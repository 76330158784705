import * as React from "react";
import {
  Header,
  Container,
  Title,
  SubTitle,
  Content,
  Images,
  ImagesItem,
  ContentDiv,
} from "../components/styles";
import Pattern from "../images/contact.png";
import styled from "styled-components";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data

const HeaderCustom = styled((props) => <Header {...props} />)`
  &:after {
    background-image: url(${Pattern});
  }
`;

const Form = styled.form`
  background: #f6ecea;
  padding: 20px;
  border-radius: 15px;
  max-width: 400px;
  label {
    display: block;
    margin-bottom: 10px;

    span {
      display: block;
    }
  }

  input,
  textarea {
    font-family: brandon-grotesque, sans-serif;
    display: block;
    width: 100%;
    background: none;
    border: 0;
    border-bottom: 2px solid #e8e0df;
    padding: 10px;

    &:focus {
      outline: 0;
      border-bottom: 2px solid #b5a5a3;
    }
  }

  textarea {
    height: 100px;
  }

  button {
    display: block;
    margin: 0 auto;
    width: 120px;
    font-family: brandon-grotesque, sans-serif;
    color: #17130f;
    text-decoration: none;
    font-size: 1.2rem;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    border: none;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "wedding" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO
        title="Wedding Cakes Rugby"
        description="Get in touch to order bespoke buttercream cakes for any occasion in Rugby, Warwickshire."
      />
      <HeaderCustom>
        <Container>
          <Title>Wedding Cake Rugby</Title>
        </Container>
      </HeaderCustom>
      <Container>
        <Content>
          <div>
            <SubTitle>Booking your Wedding Cake</SubTitle>
            <p>
              All of my wedding cakes are custom made in Rugby and tailored to
              fit the design and colour scheme of your wedding. They are created
              with the love and detail they deserve for your special day. I have
              been baking for many years. I now specialise in wedding cakes and
              dessert tables throughout Rugby and Warwickshire. Finding your
              dream wedding cake should be an enjoyable process. I will support
              you throughout, as I strive for providing you with the perfect
              service.
            </p>
          </div>
          <div>
            <SubTitle>Bespoke Wedding Cakes</SubTitle>
            <p>
              The most important cake on one of the most special days of your
              life. I only use the finest ingredients to ensure your cake tastes
              as beautiful as it looks. I can offer a wide range of designs to
              suit many styles, please find photos below for previous examples
              of my work. Ranging from more traditional designs, to a modern
              classic style. I am a buttercream specialist in Rugby, and only
              use this for the final design of my wedding cakes. You can also
              see my latest designs on my instagram page for some ideas.
            </p>
            {/* <Form
              method="post"
              action="https://getform.io/f/8a705a43-b68d-4ef7-ab6a-243d242d47a4"
              on
            >
              <label>
                <span>Name</span>
                <input type="text" name="name" required />
              </label>
              <label>
                <span>Email</span>
                <input type="email" name="email" required />
              </label>
              <label>
                <span>Phone</span>
                <input type="tel" name="phone" required />
              </label>
              <input type="hidden" name="occasion" value="Wedding" />
              <label>
                <span>Date Required</span>
                <input
                  type="date"
                  name="when"
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </label>
              <label>
                <span>Message</span>
                <textarea
                  type="text"
                  name="message"
                  required
                  placeholder="Please include flavour, size and type of cake(s) required, along with any dietry requirements."
                ></textarea>
              </label>
              <button type="submit">Book Now</button>
            </Form> */}
          </div>
        </Content>
        <ContentDiv>
          <SubTitle>Luxury Wedding Dessert Tables</SubTitle>
          <p>
            If you would like a range of sweet treats on offer for your guests,
            I am able to provide dessert tables to accompany your wedding cake.
            Examples include cupcakes, macarons, chocolate cookies, small
            individual cakes, meringue kisses and sugar biscuits. All creations
            are carefully made to compliment your wedding day. I am able to
            offer advice on which pairings of cupcakes or desserts can
            compliment your design of the main cake.
          </p>
          <p>
            Whatever your favourite sweet treat, I'll try my very best to
            deliver a wedding grazing feast for you and your guests to enjoy
            throughout your special day. Why choose just one cake when you could
            offer your guests a whole delicious buffet with a luxury grazing
            table?
          </p>
        </ContentDiv>
        <Images>
          {data.allFile.edges.slice(0, 3).map((image) => (
            <ImagesItem
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
              key={image.node.base.split(".")[0]}
            />
          ))}
        </Images>
        <ContentDiv>
          <SubTitle>Flavours of Wedding cakes</SubTitle>
          <p>
            I can offer a wide range of flavours for your cakes. Favourite
            flavours include <strong>white chocolate &amp; raspberry</strong>,{" "}
            <strong>salted caramel</strong> and{" "}
            <strong>lemon with lemon curd</strong>. A full list of flavours can
            be found on my celebration cakes page. Please get in touch if you
            would prefer a flavour not listed, as I aim to accommodate requests
            where possible.
          </p>
          <p>
            Weddings can leave you with a lot of decision making. There are
            important factors to think about for your cake, but I will guide you
            though the cake sizes, styles, tier and flavours available. Cupcake
            flavours can also be adjusted for the occasion to create your ideal
            combination.
          </p>
          <SubTitle>Wedding Cakes delivered in Rugby</SubTitle>
          <p>
            My wedding cakes are home made in Rugby. I personally deliver and
            set up wedding cakes throughout Warwickshire and sometimes further
            afield. Please let me know the location of your venue when you make
            an enquiry so we can start planning the logistics. Previous weddings
            have taken my cakes to The Cotswolds, North Warwickshire and
            Worcestershire.
          </p>
          <SubTitle>Wedding Venue</SubTitle>
          <p>
            Once your date has been confirmed, I liaise with your wedding
            coordinator at your venue to discuss the finer details of delivery
            and set up of the cake. You don't need to worry about the logistics
            as this is all taken care of as part of my cake services.
          </p>
          <SubTitle>Booking your Cake</SubTitle>
          <p>
            It's important to get your booking in as early as possible to avoid
            disappointment. For the biggest event of your life, you'll want to
            reserve the date for one of the main wedding features. My diary is
            currently open until the end of 2023.
          </p>
        </ContentDiv>
        <Images>
          {data.allFile.edges.slice(3, 6).map((image) => (
            <ImagesItem
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
              key={image.node.base.split(".")[0]}
            />
          ))}
        </Images>
        <ContentDiv>
          <SubTitle>Testimonials</SubTitle>
          <p>
            Take a read below for some perfect testimonials from weddings
            recently.
          </p>
          <div
            style={{
              background: "#f6ecea",
              padding: "5px 20px",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          >
            <h3>A &amp; R (Crumplebury, Worcestershire)</h3>
            <p>
              "Rachel recently made my wedding cake. Not only did it taste
              amazing, it looked beautiful too! All our guests commented on how
              nice the cake was. <strong>We cannot recommend her enough</strong>{" "}
              and will definitely be contacting her for more cakes!"
            </p>
          </div>
          <div
            style={{
              background: "#f6ecea",
              padding: "5px 20px",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>H &amp; A (Daventry Court Hotel, Northamptonshire)</h3>
            <p>
              "Just wanted to say thank you for my wedding cake. It was
              absolutely delicious, and{" "}
              <strong>the buttercream was insane!</strong> We had such great
              feedback from our guests."
            </p>
          </div>
          <div
            style={{
              background: "#f6ecea",
              padding: "5px 20px",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>S &amp; D (Cider Mill Barns, The Cotswolds)</h3>
            <p>
              "The cake was <strong>absolutely beautiful!</strong> Thank you so
              much! It tasted absolutely amazing too."
            </p>
          </div>
          <div
            style={{
              background: "#f6ecea",
              padding: "5px 20px",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          >
            <h3>L &amp; C (Bourton Hall, Warwickshire)</h3>
            <p>
              "Thank you so much! The cake looked beautiful,{" "}
              <strong>we had so many compliments</strong> about how amazing it
              tasted."
            </p>
          </div>
          <p>
            Please get in touch to discuss plans for your wedding cake in Rugby
            and Warwickshire.
          </p>
        </ContentDiv>
        <Images>
          {data.allFile.edges.slice(6, 9).map((image) => (
            <ImagesItem
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
              key={image.node.base.split(".")[0]}
            />
          ))}
        </Images>
      </Container>
    </React.Fragment>
  );
};

export default IndexPage;
